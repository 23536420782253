import React, { Component } from "react";
import styles from "../Modules/Footer.module.css";
import {faGithub, faLinkedinIn} from "@fortawesome/free-brands-svg-icons";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Footer extends Component {
  render() {
    return (
      <div id={styles.footer}>
            <div className={styles.socials}>
                <a
                    href="https://www.linkedin.com/in/soulaimane-elmanioui/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon className={styles.social_link} icon={faLinkedinIn} />
                </a>
                <a
                    href="https://github.com/elmaniouiSoulaimane"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <FontAwesomeIcon className={styles.social_link} icon={faGithub} />
                </a>
                <a href="mailto:elmanioui.soulaimane@gmail.com">
                    <FontAwesomeIcon className={styles.social_link} icon={faEnvelope} />
                </a>
            </div>
      </div>
    );
  }
}

export default Footer;
