import React from "react";
import styles from "../Modules/Projects.module.css";
import { ProjectCard } from "../imports";
let projectsMap = [0, 1, 2, 3];

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      projectTechnologies:"",
      description: "",
      previewImage: "",
      demo: "",
      gitRepo: "",
      viewCode: "",
      imgAlt: "",
      projectInfoPath: "",
      functionalitiesPics: "",
      modelingPics: "",
    };
  }
  static getDerivedStateFromProps(props, state) {
    return {
      name: props.name,
      projectTechnologies: props.projectTechnologies,
      description: props.description,
      previewImage: props.previewImage,
      demo: props.demo,
      demoVideo: props.demoVideo,
      gitRepo: props.gitRepo,
      viewCode: props.viewCode,
      imgAlt: props.imgAlt,
      projectInfoPath: props.projectInfoPath,
    };
  }
  render() {
    return (
      <div id="projects" className={styles.projects}>
        <h2>Projects</h2>
        <div className={styles.cards}>
          {projectsMap.map((i) => {
            return (
                <ProjectCard
                    key={i}
                    name={this.state.name[i]}
                    projectTechnologies = {this.state.projectTechnologies[i]}
                    description={this.state.description[i]}
                    previewImage={this.state.previewImage[i]}
                    demo={this.state.demo[i]}
                    demoVideo={this.state.demoVideo[i]}
                    gitRepo={this.state.gitRepo[i]}
                    viewCode={this.state.viewCode[i]}
                    imgAlt={this.state.imgAlt[i]}
                    projectInfoPath={this.state.projectInfoPath[i]}
                />
            );
          })}
        </div>
      </div>
    );
  }
  componentDidMount() {}
}

export default Projects;
