import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Component } from "react";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

import {
  Navbar,
  Home,
  Projects,
  Aboutme,
  Contact,
  Footer,
  Resume,
  InfoCard
} from "./imports";
import {
  taxflow_preview,
  potion_preview,
  go_service_preview,
  pixatopia_preview,
} from "./imports";

import {memomatch_background, taxflow_background, go_service_background, potion_background, pixatopia_background,} from "./imports";
import {memomatch_logo, taxflow_logo, go_service_logo, potion_Logo, pixatopia_logo} from "./imports";
import {memomatch_screenshot, taxflow_screenshot, go_service_screenshot, potion_screenshot, pixatopia_screenshot} from "./imports";
import {memomatch_room_details_form, taxeflow_infrastructure, go_service_database_diagram, potion_database_diagram} from "./imports"

let projectsInfoMap = [0, 1, 2, 3, 4];
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: [
        "Memomatch",
        "Taxes Platform",
        "Handyman Services",
        "Pharmacy Desktop App",
        "Javascript based pixels platform",
      ],
      logo: [
        memomatch_logo,
        taxflow_logo,
        go_service_logo,
        potion_Logo,
        pixatopia_logo
      ],
      description: [
        "Built with Django, Python, and React, this project showcases real-time communication via WebSockets for a fast, interactive gaming experience.",
        "An innovative platform meticulously designed to streamline the intricate process of calculating taxes on non-inhabited lands, empowering government commune bureaus to harness technology for optimized fiscal administration.",
        "Your go-to platform for seamless connections between skilled handymen and clients. From repairs to renovations, find trusted professionals for all your needs. Simplifies booking, ensures reliability,",
        "Your comprehensive desktop solution for efficient pharmacy management. Streamline inventory, track prescriptions, manage patient records, and simplify billing all in one place.",
        "Unleash your creativity with the ultimate pixel art platform. Craft intricate designs one pixel at a time, harnessing a digital canvas that brings your imagination to life. Transform pixels into masterpieces, pixel by pixel.",
        ],
      previewImage:[
        memomatch_logo, taxflow_preview, go_service_preview, potion_preview, pixatopia_preview
      ],
      demo: [
        "http://164.92.235.68:3000/",
        "",
        "",
        "",
        "",
      ],
      gitRepo: [
        "https://github.com/elmaniouiSoulaimane/memomatch.git",
        "https://github.com/elmaniouiSoulaimane/Tax-Flow-Back-end.git",
        "https://github.com/elmaniouiSoulaimane/Gestion-Des-Services.git",
        "https://github.com/elmaniouiSoulaimane/Potion-V1.git",
        "https://github.com/elmaniouiSoulaimane/Pixatopia.git",
      ],
      viewCode: ["pointer", "pointer", "pointer", "pointer", "pointer"],
      imgAlt: [
        "Memomatch project logo",
        "Taxe-Flow project logo",
        "Go Service project logo",
        "Potion project logo",
        "Pixatopia project logo",
      ],
      projectInfoPath: [
        "/project/memomatch",
        "/project/taxe-flow",
        "/project/Go-Service",
        "/project/potion",
        "/project/pixatopia",
      ],
      projectTechnologies: [
        [
          "Python",
          "Django",
          "React",
          "WebSockets",
          "Redis",
          "HTML",
          "CSS",
          "JavaScript",
          "JQuery",
        ],
        [
          "UML",
          "MySQL",
          "JAVA",
          "JEE",
          "SpringBoot",
          "Spring JPA",
          "Spring DATA",
          "Spring REST API",
        ],
        [
          "MERISE",
          "C#",
          ".NET",
          "ASP.NET",
          "HTML",
          "CSS",
          "JavaScript",
          "JQuery",
        ],
        [
          "UML",
          "SQL Server",
          "Windows Forms",
          "C#",
          ".NET",
          "Entity Framework",
          "LINQ",
          "ADO.NET",
        ],
        ["HTML", "CSS", "JavaScript", "JQuery"],
      ],
      projectDescription: [
        "Welcome to the Memory Cards Game! This project was built to showcase the power of real-time communication using WebSockets. Unlike traditional HTTP requests that require constant refreshing, WebSockets allow for instant, two-way interaction between the server and the browser.",
        "This my bachelor’s degree end of year project. A project about creating a plateforme for the local commune centers in Morocco to manage taxes on uninhabited lands.",
        "Web application that is a medium for service providers and their clients.",
        "Potion is a desktop windows application made for the purpose of taking your pharmacy business to the next level with interesting and useful technologies",
        "A pixelated theme plateforme made for the purpose of bonding and being creative with other individuals accross the internet.",
        ],
      projectFunctionalities: [
        "The goal of this game is not just to entertain, but to demonstrate how modern web applications can provide a smooth, dynamic user experience with live updates.",
        "The project’s idea was my teacher’s, which he then proposed to us the basic functionalities :CRUD operations on a relational database schema.The me and me and my project partner added other functionalities:My Partner’s : staff management of the commune, with each one with he’s role.Using Spring security to manage staff’s routing.I added the ability to create taxes, taxe penalties and exonerations automatically after a staff member enters the details of a land amd it’s owner, after that the staff can sit and relax and sip his coffee, while the algorithm calculates taxes, adds penalties each month if the taxe is not payed, and exonerate the land owners that has the right to, and all of this programmed to run 24/7.",
        "As a medium between service providers and their clients, Go Service has theme of a social media, clients can post about their needs in the news feed where the service providers can read their posts, in case they're interested, the service providers can mark the post as taken. the plateforme has seperate login forms one for the service providers and the other for the clients, each user has their right of acces to the plateforms functionalities",
        "To manage a pharmacy business, the users needs to keep track of the whole aspects of a pharmacy so that's why it's built on CRUD operations(Create, Read, Update & delete) meaning the users can add new data depending on the entity or the class, get the data and display it, update the data's informations, and delete unwanted data.All of the functionalities are the following : CRUD for clients class, CRUD for medecin class, CRUD for pharmacist with the addition of a logging in using an encrypted password, CRUD for doctors, Create/Read for prescriptions, and an option to print the medecins data, using SAP CRYSTAL Reports",
        "this project is about a new fun way to play with friends, express your ideas, your personality, the things you like, to discover new people and interests, all by participating in filling a grid of multiple pixels with images, links and colors, to make at the end one big artistic image of multiple pictures and colors.",
        ],
      projectModeling: [
        "The backend is powered by Django and Python, while the frontend is built with React, all working together to create a fast and responsive game environment.",
        "Using Java SpringBoot on the backend has separated the application to multiple layers (beans, data access objects, services, web services) makes the application performing and easy to manage with the sets of technologies that springboot offers, Spring jpa for the beans, Spring data for the data access objects and finally Spring Rest API that helps manage the routing of the application.",
        "The database consists of two main tables, 1-service providers, 2-clients, there is one relation between these tables, and that is the service table, and an additional table for the job categories of the service providers",
        "The application consist of a total of five entities : Clients, Prescriptions, Medecin, Doctors, Receipts, Suppliers. If you see the code you'll notice that the namings are written in French which was the language that we use in our countrie's educational system. The diagrammes can be shown in the pictures o the left, which they explain the back-end's architecture and the relations between classes.",
        "",
        ],
      functionalitiesPics: [
        memomatch_screenshot, 
        taxflow_screenshot, 
        go_service_screenshot, 
        potion_screenshot, 
        pixatopia_screenshot
      ],
      modelingPics: [
        memomatch_room_details_form, 
        taxeflow_infrastructure, 
        go_service_database_diagram, 
        potion_database_diagram, 
        ""
      ],
      demoVideos: [
        "",
        "",
        "",
        "https://www.youtube.com/embed/Z5PG7URJ0MA?list=PL2CRKbF8ZQvMOkQkOYB8HFklkNg-eDFO9",
        "https://www.youtube.com/embed/yqArdR_2ynU",
      ],
      caseStudyBackgrounds: [
          memomatch_background, 
          taxflow_background, 
          go_service_background, 
          potion_background, 
          pixatopia_background
      ],
      backgrounds_attributions:[
        <>
          Background photo by &nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@foxandhyde?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            Fox & Hyde
          </a>&nbsp;
          on&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/photos/a-bunch-of-cards-that-are-sitting-on-a-table-ZgwI4MvdhEk?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash">
            Unsplash
          </a>
        </>,
        <>
          Background photo by &nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@walkingondream?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Olga DeLawrence
          </a>&nbsp;
          on&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/photos/5616whx5NdQ?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </>,
        <>
          Background photo by&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@greysonjoralemon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Greyson Joralemon
          </a>&nbsp;
          on&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/photos/A1g0oeX29ec?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </>,
        <>
          Background photo by&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@tbelabuseridze?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Tbel Abuseridze
          </a>&nbsp;
          on&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/photos/eBW1nlFdZFw?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </>,
        <>
          Background photo by&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/@pbernardon?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Pascal Bernardon
          </a>&nbsp;
          on&nbsp;
          <a target="_blank" rel="noopener noreferrer" href="https://unsplash.com/photos/zWHZ_QsU4rc?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
            Unsplash
          </a>
        </>
      ]
    };
  }
  render() {
    return (
      <>
        <ReactNotifications />
        <Router >
          <div id="app">
            <Switch>
              <Route exact path="/">
                <Navbar />
                <Home />
                <Projects
                  name={this.state.name}
                  projectTechnologies={this.state.projectTechnologies}
                  description={this.state.description}
                  previewImage = {this.state.previewImage}
                  demo={this.state.demo}
                  demoVideo={this.state.demoVideos}
                  gitRepo={this.state.gitRepo}
                  viewCode={this.state.viewCode}
                  imgAlt={this.state.imgAlt}
                  projectInfoPath={this.state.projectInfoPath}
                />
                <Aboutme />
                <Contact />
                <Footer />
              </Route>
              <Route exact path="/resume">
                <Resume />
              </Route>
              {projectsInfoMap.map((i) => {
                return (
                  <Route key={i} exact path={this.state.projectInfoPath[i]}>
                    <InfoCard
                      key={i}
                      name={this.state.name[i]}
                      logo={this.state.logo[i]}
                      demo={this.state.demo[i]}
                      gitRepo={this.state.gitRepo[i]}
                      previewImage = {this.state.previewImage}
                      viewCode={this.state.viewCode[i]}
                      imgAlt={this.state.imgAlt[i]}
                      projectTechnologies={this.state.projectTechnologies[i]}
                      projectDescription={this.state.projectDescription[i]}
                      projectFunctionalities={
                        this.state.projectFunctionalities[i]
                      }
                      projectModeling={this.state.projectModeling[i]}
                      functionalitiesPics={this.state.functionalitiesPics[i]}
                      modelingPics={this.state.modelingPics[i]}
                      demoVideo={this.state.demoVideos[i]}
                      backgroundPicture={this.state.caseStudyBackgrounds[i]}
                      backgrounds_attributions={this.state.backgrounds_attributions[i]}
                    />
                  </Route>
                );
              })}
            </Switch>
          </div>
        </Router>
      </>
    );
  }
}

export default App;
