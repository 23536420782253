import React from "react";
import styles from "../Modules/ProjectCard.module.css";

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      previewImage: "",
      demo: "",
      gitRepo: "",
      viewCode: "pointer",
      imgAlt: "project logo",
      projectInfoPath: "",
      projectTechnologies:[],
    };
  }
  static getDerivedStateFromProps(props) {
    return {
      name: props.name,
      projectTechnologies:props.projectTechnologies,
      description: props.description,
      previewImage: props.previewImage,
      demo: props.demo,
      demoVideo: props.demoVideo,
      gitRepo: props.gitRepo,
      viewCode: props.viewCode,
      imgAlt: props.imgAlt,
      projectInfoPath: props.projectInfoPath,
    };
  }
  render() {
    return (
        <div className={styles.card} id={this.state.name}>
          <h4 className={styles.projName}>{this.state.name}</h4>
          <div className={styles.container}>
            <img src={this.state.previewImage} className={styles.previewImage} alt={""}/>
            <div className={styles.text}>
              <div className={styles.technologies}>
                {this.state.projectTechnologies.map((tech) => {
                  return <span key={tech}>{tech}</span>;
                })}
              </div>
              <p className={styles.description}>{this.state.description}</p>
              <div className={styles.buttons}>
                {this.state.demo ?
                    <a
                        className={styles.card_link}
                        href={this.state.demo}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Use this project"
                    >
                      Use this project
                    </a>
                    :
                    <></>
                }
                {this.state.demoVideo ?
                    <a
                        className={styles.card_link}
                        href={this.state.demoVideo}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Use this project"
                    >
                      Watch Demo Video
                    </a>
                    :
                    <></>
                }
                <a
                    className={styles.card_link}
                    href={this.state.gitRepo}
                    title="Details"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                  Source code
                </a>
                <a
                    className={styles.card_link}
                    href={this.state.projectInfoPath}
                    rel="noopener noreferrer"
                >
                  Read more
                </a>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default ProjectCard;
