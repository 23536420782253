import React, { useRef, useState } from "react";
import styles from "../Modules/Contact.module.css";
import emailjs from "emailjs-com";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone, faUser, faCircleNotch} from "@fortawesome/free-solid-svg-icons";

export default function Contact() {
  const [notification, setNotification] = useState(<></>);
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    setNotification(<FontAwesomeIcon className={styles.spinner} icon={faCircleNotch}/>)

    emailjs
      .sendForm(
        "service_40tsp29",
        "template_d096wnh",
        form.current,
        "user_hTsuLMwZZ8KJVZK2rXOLP"
      )
      .then(
        (result) => {
          if (result.status === 200){
            setNotification(<span className={styles.notification}>Message sent successfully.</span>);
            e.target.reset();
          }
        },
        (error) => {
          setNotification(<span className={styles.notification}>Error! Please try again.</span>);
        }
      );
  };

  return (
    <div id="contact" className={styles.contact}>
      <div className={styles.subcontainer}>
        <form ref={form} onSubmit={sendEmail} className={styles.form}>
          <div className={styles.field}>
            <label>E-mail
              <input
                  type="email"
                  required
                  name="user_email"
              ></input>
            </label>
            
          </div>
          <div  className={styles.field}>
            <label>Name
              <input
                type="text"
                required
                name="user_name"
              ></input>
            </label>
            
          </div>
          <div  className={styles.field}>
            <label>Message
              <textarea
                id={styles.textarea}
                required
                name="message"
              ></textarea>
            </label>
          </div>
          <div className={styles.send}>
            <input type="submit" className={styles.submit} value="Send"></input>
            {notification}
          </div>
        </form>
        <div className={styles.right_side}>
          <div className={styles.testimonials}>
            <h3>Testimonials</h3>
            <div className={styles.testimony}>
              <div className={styles.referral}>
                <FontAwesomeIcon className={styles.icon} icon={faUser} />
                <span>Youssef Essadik Elmejjad:</span>
              </div>
              <p>"...I highly value his skills and recommend him as a valuable member for any team"</p>
            </div>
          </div>
          <div>
            <h4>Get In Touch.</h4>
            <div className={styles.contact_info}>
              <FontAwesomeIcon className={styles.social_link} icon={faEnvelope} />
              <span>elmanioui.soulaimane@gmail.com</span>
            </div>
            <div className={styles.contact_info}>
              <FontAwesomeIcon className={styles.social_link} icon={faPhone} />
              <span>+212 693008991</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
