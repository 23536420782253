import React from "react";
import styles from "../Modules/Navbar.module.css";
import { Link } from "react-scroll";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
require("../index.css")

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.changeNavColor = this.changeNavColor.bind(this);
    this.changeLinksColor = this.changeLinksColor.bind(this);
    this.myFunction = this.myFunction.bind(this);
    this.navbarReturn = this.navbarReturn.bind(this);
    this.state = {
      backgroundColor: "transparent",
      boxShadow: "none",
      color: "var(--dark-green)",
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.changeNavColor);
    window.addEventListener("scroll", this.changeLinksColor);
    window.addEventListener("resize", this.navbarReturn);
    window.addEventListener("resize", this.linksReturn);
  }

  changeNavColor() {
    this.setState({
      backgroundColor: "var(--hover-green)",
      boxShadow: "0 0 4px black",
    });
    if (window.scrollY === 0) {
      this.setState({
        backgroundColor: "transparent",
        boxShadow: "none",
      });
    }
  }

  changeLinksColor() {
    this.setState({
      color: "var(--white)",
    });

    if (window.scrollY === 0) {
      this.setState({
        color: "var(--dark-green)",
      });
    }
  }

  myFunction() {
    var x = document.getElementById("mynavbar");
    if (x.className === styles.navbar) {
      x.className = styles.responsive;
      this.setState({
        color: "var(--white)",
      });
    } else {
      x.className = styles.navbar;
      if (window.scrollY === 0){
        this.setState({
          color: "var(--dark-green)",
        });
      }

    }
  }

  navbarReturn() {
    var x = document.getElementById("mynavbar");
    if (x.className === styles.responsive) {
      x.className = styles.navbar;
    }
  }

  linksReturn() {
    var x = document.getElementsByClassName("link");
    if (x.className === styles.responsive) {
      x.className = styles.link;
    }
  }

  render() {
    return (
      <div
        id="mynavbar"
        className={styles.navbar}
        style={{
          backgroundColor: this.state.backgroundColor,
          boxShadow: this.state.boxShadow,
        }}
      >
        <ul>
          <li>
            <Link
              className={styles.link}
              style={{
                color: this.state.color,
              }}
              to={"home"}
              activeClass={styles.active}
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className={styles.link}
              style={{
                color: this.state.color,
              }}
              to={"projects"}
              activeClass={styles.active}
              spy={true}
              smooth={true}
              offset={-70}
              duration={1000}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
                className={styles.link}
                style={{
                  color: this.state.color,
                }}
                to={"aboutme"}
                activeClass={styles.active}
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
            >
              About Me
            </Link>
          </li>
          <li>
            <Link
                className={styles.link}
                style={{
                  color: this.state.color,
                }}
                to={"contact"}
                activeClass={styles.active}
                spy={true}
                smooth={true}
                offset={-70}
                duration={1000}
            >
              Contact
            </Link>
          </li>
          <li>
            <a
                className={styles.link}
                style={{
                  color: "var(--dark-green)",
                  backgroundColor: "var(--light-green)",
                }}
                target="_blank"
                rel="noopener noreferrer"
                href="/resume">
              Resume
              <svg xmlns="http://www.w3.org/2000/svg"
                   height="1em"
                   viewBox="0 0 512 512"
                   className={"icon"}
                   style={{
                     fill: "var(--dark-green)",
                     fontSize: "inherit"
                   }}
              >
                {/*! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.*/}
                <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
              </svg>
            </a>
          </li>
          <li>
            <button
                className={styles.hamburger}
                style={{
                  color: this.state.color,
                }}
                onClick={this.myFunction}>
              <FontAwesomeIcon icon={faBars} className={styles.icon} />
            </button>
          </li>
        </ul>
      </div>
    );
  }
}

export default Navbar;
