import { Component } from "react";
import styles from "../Modules/Resume.module.css";
import { resume } from "../imports";

class Resume extends Component {
  render() {
    return (
      <object className={styles.pdf} data={resume} type="application/pdf">
          <p>Unable to display PDF file. <a href="/uploads/media/default/0001/01/540cb75550adf33f281f29132dddd14fded85bfc.pdf">Download</a> instead.</p>
      </object>    
    );
  }
}

export default Resume;
